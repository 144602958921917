import React from "react";

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

//Images
import brooks from "assets/img/profile.jpeg";
import conway from "assets/img/conway.png";
import cpeu from "assets/img/cpeu.png";
import larkist from "assets/img/larkist.gif";
import lifeGPA from "assets/img/lifeGPA.png";
import nba from "assets/img/nba.png";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  handleSubmit = e => {
    if (!this.state.name || !this.state.email || !this.state.message) {
      alert("Pleae fill in your name, email address, and message.");
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      }).catch(error => alert(error));
    }

    e.preventDefault();
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    // const { name, email, message } = this.state;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    return (
      <div>
        <Parallax
          image={require("assets/img/sf.jpg")}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={brooks} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h1 className={classes.title}>Brooks Vinyard</h1>

                    {/* <h6>ENGINEER</h6> */}
                    <Button
                      justIcon
                      simple
                      size="lg"
                      color="linkedin"
                      href="https://www.linkedin.com/in/brooksvinyard/"
                      target="_blank"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button
                      justIcon
                      simple
                      size="lg"
                      color="github"
                      href="https://github.com/brooksvinyard"
                      target="_blank"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-github"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div
              className={classNames(classes.description, classes.textCenter)}
            >
              <h3>
                I am an innovative, forward-thinking engineer, constantly on the
                lookout to optimize, with an ability to analyze and clearly
                communicate technical principles. Skilled with Python,
                JavaScript, React, Node.js, SQL, and more. Experience in
                biotech, R&D, and industrial settings. Born to think outside the
                box, dive into new fields and technologies, and dream big.{" "}
              </h3>
            </div>

            <div>
              {/* Projects START */}
              <div className={classes.projects}>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    className={`${classes.mlAuto} ${classes.mrAuto} ${
                      classes.textCenter
                    }`}
                  >
                    <h2 className={classes.title}>Selected Projects</h2>

                    <div className={classes.tabSpace} />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card
                      raised
                      background
                      style={{ backgroundImage: `url(${cpeu})` }}
                    >
                      <CardBody background>
                        <h6 className={classes.cardCategory}>
                          React | Node.js | Gatsby | Python | PostgreSQL |
                          GraphQL | Docker | AWS | CI/CD
                        </h6>
                        <a href="#cpeu" onClick={e => e.preventDefault()}>
                          <h2 className={classes.cardTitleWhite}>CPEU.org</h2>
                        </a>
                        <h3 className={classes.cardDescription}>
                          CPEU.org is a search engine for Registered Dietitians
                          to find and filter over 34,000 educational
                          opportunities needed to maintain their credentials.
                          Built landing page, frontend, backend, database, and
                          automated scraper.
                        </h3>
                        <Button
                          round
                          color="info"
                          href="https://cpeu.org/"
                          target="_blank"
                        >
                          <Icon>content_copy</Icon> View Site
                        </Button>
                      </CardBody>
                    </Card>
                    <Card
                      raised
                      background
                      style={{ backgroundImage: `url(${larkist})` }}
                    >
                      <CardBody background>
                        <h6 className={classes.cardCategory}>
                          React | Redux | Material UI | Node.js | PostgreSQL
                        </h6>
                        <a href="#larkist" onClick={e => e.preventDefault()}>
                          <h2 className={classes.cardTitleWhite}>Larkist</h2>
                        </a>
                        <h3 className={classes.cardDescription}>
                          Larkist is an app that uses AI to help create a
                          Twitter list of similar people based off user input of
                          a few Twitter handles. The app also uses AI to perform
                          sentiment analysis on your Twitter timeline. This
                          analysis will then show a sortable list of the top
                          toxic or offensive tweets in your timeline with the
                          option to block those users.
                        </h3>
                        <Button
                          round
                          color="info"
                          href="https://larkist.com/"
                          target="_blank"
                        >
                          <Icon>content_copy</Icon> View App
                        </Button>
                        <Button
                          simple
                          color="white"
                          href="https://github.com/labs12-twitter-blocker"
                          target="_blank"
                        >
                          <i className={"fab fa-github"} />
                          GitHub
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card
                      raised
                      background
                      style={{ backgroundImage: `url(${nba})` }}
                    >
                      <CardBody background>
                        <h6 className={classes.cardCategory}>
                          Node.js | Express | PostgreSQL
                        </h6>
                        <a href="#nba" onClick={e => e.preventDefault()}>
                          <h2 className={classes.cardTitleWhite}>
                            NBA Career Predictor
                          </h2>
                        </a>
                        <h3 className={classes.cardDescription}>
                          Collaborated with a team of web developers and data
                          scientists to build an app that will predict the
                          length of an NBA player’s career.
                        </h3>
                        <Button
                          round
                          color="info"
                          href="https://nbacareerlength.netlify.com/"
                          target="_blank"
                        >
                          <Icon>content_copy</Icon> View App
                        </Button>
                        <Button
                          simple
                          color="white"
                          href="https://github.com/nba-career/backend"
                          target="_blank"
                        >
                          <i className={"fab fa-github"} />
                          GitHub
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card
                      raised
                      background
                      style={{ backgroundImage: `url(${lifeGPA})` }}
                    >
                      <CardBody background>
                        <h6 className={classes.cardCategory}>
                          React | Redux | CSS
                        </h6>
                        <a href="#lifegpa" onClick={e => e.preventDefault()}>
                          <h2 className={classes.cardTitleWhite}>Life GPA</h2>
                        </a>
                        <h3 className={classes.cardDescription}>
                          Created a React Redux front-end that allows you to
                          record daily life habits in order to compute an
                          overall life GPA.
                        </h3>
                        <Button
                          round
                          color="info"
                          href="https://lifegpa.netlify.com/"
                          target="_blank"
                        >
                          <Icon>content_copy</Icon> View App
                        </Button>
                        <Button
                          simple
                          color="white"
                          href="https://github.com/bw-life-gpa/frontend-life-gpa"
                          target="_blank"
                        >
                          <i className={"fab fa-github"} /> GitHub
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card
                      raised
                      background
                      style={{ backgroundImage: `url(${conway})` }}
                    >
                      <CardBody background>
                        <h6 className={classes.cardCategory}>React </h6>
                        <a href="#gol" onClick={e => e.preventDefault()}>
                          <h2 className={classes.cardTitleWhite}>
                            Conway&apos;s Game of Life
                          </h2>
                        </a>
                        <h3 className={classes.cardDescription}>
                          I built a retro version of Conway&apos;s Game of Life
                          in React. The game was devised in 1970 to simulate
                          cellular automaton.
                        </h3>
                        <Button
                          round
                          color="info"
                          href="https://conways.netlify.com/"
                          target="_blank"
                        >
                          <Icon>content_copy</Icon> View App
                        </Button>
                        <Button
                          simple
                          color="white"
                          href="https://github.com/brooksvinyard/conway"
                          target="_blank"
                        >
                          <i className={"fab fa-github"} /> GitHub
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
              {/* Contact START */}
              <div className={classes.section}>
                <GridContainer justify="center">
                  <GridItem cs={12} sm={8} md={8}>
                    <h2 className={classes.title}>Contact me</h2>
                    <h4 className={classes.description}>
                      Go ahead and send me a message. I will get back to you as
                      soon as I can!
                    </h4>
                    <form
                      name="contact"
                      netlify="true"
                      method="post"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                      onChange={this.handleChange}
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            required
                            labelText="Your Name"
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            required
                            labelText="Your Email"
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <CustomInput
                          required
                          labelText="Your Message"
                          id="message"
                          name="message"
                          value={this.state.message}
                          onChange={this.handleChange}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.textArea
                          }}
                          inputProps={{
                            multiline: true,
                            rows: 3
                          }}
                        />
                        <GridItem
                          xs={12}
                          sm={4}
                          md={4}
                          className={`${classes.mrAuto} ${classes.mlAuto}`}
                        >
                          <Button type="submit" color="primary">
                            Send Message
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </GridItem>
                </GridContainer>
              </div>
              {/* Contact END */}

              <Clearfix />
            </div>
          </div>
        </div>

        <Footer
          content={
            <div>
              <div className={classes.left}>
                {/* <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.brooksvinyard.com/"
                      className={classes.block}
                    >
                      Brooks Vinyard
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://github.com/brooksvinyard"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.block}
                    >
                      GitHub
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.linkedin.com/in/brooksvinyard/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.block}
                    >
                      LinkedIn
                    </a>
                  </ListItem>
                </List> */}
              </div>
              <div className={classes.center}>
                &copy; {1900 + new Date().getYear()} Brooks Vinyard
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(Profile);
